'use client'

import { useState } from 'react'
import { useRouter } from 'next/navigation'
import { createClientComponentClient } from '@supabase/auth-helpers-nextjs'
import { useAuth } from '@/components/app/auth_provider'
import { Eye, EyeOff } from 'lucide-react'

export default function Page() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [safety, setSafety] = useState(true);
    const [error, setError] = useState<string | null>(null)
    const [loading, setLoading] = useState(false)
    const router = useRouter()
    const supabase = createClientComponentClient()
    const { session } = useAuth()

    const handleRestore = async (e: React.FormEvent) => {
        e.preventDefault()
        setError(null)
        setLoading(true)
        try {
            const result = await supabase.auth.signInWithPassword({ email, password })
            if (result.error) throw result.error
            router.push('/')
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message)
            } else {
                setError('An unexpected error occurred')
            }
        } finally {
            setLoading(false)
        }
    }

    const toggleVisibility = () => setSafety(prevState => !prevState);

    if (session) {
        return null
    }

    return (
        <div className="w-[32rem] px-2 pt-4">
            <div className="flex justify-center items-center mt-2 mb-8">
                <img src="/logo.png" width={200} height={26} />
            </div>
            <div className="px-6 py-4 lg:px-8 lg:py-6 text-left bg-white shadow-lg rounded-lg max-w-lg">
                <h3 className="text-2xl lg:text-2xl text-center mb-4">Log In</h3>
                <form onSubmit={handleRestore}>
                    <div className="mt-4">
                        <label className="block" htmlFor="email">Email</label>
                        <input
                            type="email"
                            autoComplete="email"
                            className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                    </div>
                    <div className="mt-4">
                        <label className="block">Password</label>
                        <div className="relative">
                            <input
                                type={safety ? "password" : "text"}
                                autoComplete='current-password'
                                className="w-full px-4 py-2 mt-2 border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-600"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                            <button
                                className="absolute inset-y-0 end-0 flex items-center z-20 px-2.5 cursor-pointer text-gray-400 rounded-e-md focus:outline-none focus-visible:text-indigo-500 hover:text-indigo-500 transition-colors"
                                type="button"
                                onClick={toggleVisibility}
                                aria-label={safety ? "Show password" : "Hide password"}
                                aria-pressed={safety == false}
                                aria-controls="password"
                            >
                                {safety == false ? (
                                    <EyeOff size={20} aria-hidden="true" className="text-blue-500" />
                                ) : (
                                    <Eye size={20} aria-hidden="true" />
                                )}
                            </button>
                        </div>
                    </div>
                    {error && <div className="text-red-500 mt-2">{error}</div>}
                    <div className="flex items-baseline justify-between mt-8 mb-4">
                        <button
                            type="submit"
                            // className="px-6 py-2 text-white bg-blue-600 rounded-lg hover:bg-blue-900"
                            className="w-full px-6 py-2 text-white bg-gray-800 rounded-lg hover:bg-gray-700"
                            disabled={loading}
                        >
                            {loading ? 'Processing...' : 'Login'}
                        </button>
                    </div>
                </form>
            </div>

            <p className="text-center text-gray-700 mt-4">
                <a href="/register" className="underline mr-8">
                    Create an account
                </a>

                <a href="/restore" className="underline">
                    I forgot my password
                </a>
            </p>
        </div>
    )
}
